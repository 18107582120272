export type ResourceDictionary = typeof CzechResourcesDictionary;

export const CzechResourcesDictionary = {
  CustomInstallments: "Splátky na míru",
  Months: "{{count}} měsíc",
  Months_one: "{{count}} měsíc",
  Months_two: "{{count}} měsíce",
  Months_few: "{{count}} měsíce",
  Months_other: "{{count}} měsíců",
  EnterDownPayment: "Kolik zaplatíte předem",
  MonthlyInstallment: "Měsíční splátka",
  MonthsWithoutCount: "měsíc ",
  MonthsWithoutCount_one: "měsíc",
  MonthsWithoutCount_two: "měsíce",
  MonthsWithoutCount_few: "měsíce",
  MonthsWithoutCount_other: "měsíců",
  CreditAmount: "Výše úvěru",
  TotalAmountPaid: "Celkově zaplacená částka",
  AnnualInterestRate: "Roční úroková sazba",
  APN: "RPSN",
  CZK: "Kč",
  CalculateInstallments: "Chci si spočítat splátky",
  SelectInstallment: "Vybrat splátky",
  ShowInstallmentDetail: "Zobrazit detail splátek",
  HideInstallmentDetail: "Skrýt detail splátek",
  EnterPeriod: "Zadejte dobu splácení",
};
